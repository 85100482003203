import { Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { BottomBarContext } from "context/BottomBar";
import * as React from "react";
import { useContext, useState } from "react";
import TabHomeNavigator from "./Home";
import TabJournalNavigator from "./Journal";
import TabProfileNavigator from "./Profile";

export type BottomTabParamList = {
  Journal: undefined;
  Home: undefined;
  Profile: undefined;
};

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator(): React.ReactElement {
  const [displayBottomBar, setDisplayBottomBar] = useState(true);
  const bottomBarContext = useContext(BottomBarContext);

  bottomBarContext.setDisplayBottomBar = (visible: boolean) => {
    setDisplayBottomBar(visible);
  };

  return (
    <BottomTab.Navigator
      initialRouteName="Journal"
      tabBarOptions={{ activeTintColor: "#444" }}
    >
      <BottomTab.Screen
        name="Journal"
        component={TabJournalNavigator}
        options={() => {
          return {
            tabBarVisible: displayBottomBar,
            // eslint-disable-next-line react/prop-types
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="ios-journal" color={color} />
            ),
          };
        }}
      />
      <BottomTab.Screen
        name="Home"
        component={TabHomeNavigator}
        options={() => {
          return {
            tabBarVisible: displayBottomBar,
            // eslint-disable-next-line react/prop-types
            tabBarIcon: ({ color }) => (
              <TabBarIcon name="ios-home" color={color} />
            ),
          };
        }}
      />
      <BottomTab.Screen
        name="Profile"
        component={TabProfileNavigator}
        options={{
          // eslint-disable-next-line react/prop-types
          tabBarIcon: ({ color }) => (
            <TabBarIcon name="person-circle" color={color} />
          ),
        }}
      />
    </BottomTab.Navigator>
  );
}

function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={28} style={{ marginBottom: -5 }} {...props} />;
}
