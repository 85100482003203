import React, { useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { Home, JournalView } from "screens/home";
import { BottomBarContext } from "context/BottomBar";

export type TabHomeParamList = {
  Home: undefined;
  JournalView: {
    id: string;
    username: string;
  };
};

const TabHomeStack = createStackNavigator<TabHomeParamList>();

export default function TabHomeNavigator(): React.ReactElement {
  const { setDisplayBottomBar } = useContext(BottomBarContext);
  return (
    <TabHomeStack.Navigator>
      <TabHomeStack.Screen
        name="Home"
        component={Home}
        options={{ headerTitle: "Home" }}
      />
      <TabHomeStack.Screen
        name="JournalView"
        component={JournalView}
        options={{ headerShown: false }}
        listeners={{
          focus: () => {
            setDisplayBottomBar(false);
          },
          beforeRemove: () => {
            setDisplayBottomBar(true);
          },
        }}
      />
    </TabHomeStack.Navigator>
  );
}
