import React, { useState } from "react";
import { KeyboardAvoidingView, Platform, Text, View } from "react-native";
import { Button, Spacer, InputEmail, InputPassword } from "components/ui";
import { SignInProps } from "navigation/Root";
import { AuthContext } from "context/Auth";
import { styles } from "./styles";

export default function ({ navigation }: SignInProps): React.ReactElement {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <KeyboardAvoidingView
      enabled
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <View style={styles.main}>
        <View style={styles.header}>
          <Text
            style={{
              fontSize: 19,
              fontStyle: "italic",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            WTF
          </Text>
        </View>
        <View style={styles.body}>
          <InputEmail onChangeText={setEmail} defaultValue={email} />
          <InputPassword onChangeText={setPassword} defaultValue={password} />
          <Spacer size="lg" />
          <AuthContext.Consumer>
            {({ signUp }) => {
              return (
                <Button
                  onPress={() => {
                    signUp(email, password);
                  }}
                  disabled={email.length === 0 || password.length === 0}
                  title="Sign Up"
                />
              );
            }}
          </AuthContext.Consumer>
        </View>
        <View style={styles.footer}>
          <Text
            onPress={() => {
              navigation.navigate("SignIn");
            }}
          >
            Already Registred? Please, sign-in.
          </Text>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}
