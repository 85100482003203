import * as React from "react";

export type BottomBarContextProps = {
  setDisplayBottomBar: (visible: boolean) => void;
};

export const BottomBarContext = React.createContext<BottomBarContextProps>({
  setDisplayBottomBar: () => {
    undefined;
  },
});
