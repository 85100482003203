import React, { useEffect, useState } from "react";
import { Image as RNImage, ViewProps, Dimensions } from "react-native";
import { imageRef, imageType } from "database/Image";
import { Ionicons } from "@expo/vector-icons";
import * as FileSystem from "expo-file-system";
import Firebase from "firebase";

export type ImageProps = {
  image: imageRef | undefined;
  style?: ViewProps["style"];
  maxHeight?: number;
};

const win = Dimensions.get("window");

export default function Image(props: ImageProps): React.ReactElement {
  const [img, setImg] = useState<{
    image: imageType | undefined;
    uri: string | undefined;
    opacity: number;
  }>({ image: undefined, uri: undefined, opacity: 1 });

  useEffect(() => {
    if (props.image) {
      const unsubscribe = props.image.onSnapshot(async (img) => {
        const _img = img.data() as imageType;
        if (_img) {
          // Create Optimized Picture if it does not
          if (_img.picture960 === undefined && _img.picture !== undefined) {
            // Create 960 Picture
            if (_img.touch === undefined) {
              img.ref.set(
                { touch: Math.floor(Date.now() / 1000) },
                { merge: true }
              );
            }
          } else if (_img.picture960 !== undefined) {
            // Delete Touch
            if (_img.touch !== undefined) {
              img.ref.update({ touch: Firebase.firestore.FieldValue.delete() });
            }
            setImg({ image: _img, uri: _img.picture960, opacity: 1 });
          } else if (
            _img.local !== undefined &&
            _img.local.substr(0, 5) === "file:"
          ) {
            try {
              await FileSystem.getInfoAsync(_img.local);
              setImg({ image: _img, uri: _img.local, opacity: 0.4 });
            } catch (e) {
              setImg({ image: _img, uri: undefined, opacity: 1 });
            }
          } else {
            setImg({ image: _img, uri: undefined, opacity: 1 });
          }
        }
      });
      return () => {
        unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.image?.id]);

  const w = win.width * 0.9;
  let h = 0;
  if (img.image && img.image.height && img.image.width) {
    h = (w * img.image.height) / img.image.width;
    if (props.maxHeight !== undefined && h > props.maxHeight) {
      h = props.maxHeight;
    }
  }

  return (
    <>
      {img.image !== undefined && (
        <RNImage
          style={{
            height: h,
            width: w,
            backgroundColor: img.uri === undefined ? "#ede" : "#eee",
            opacity: img.opacity,
            resizeMode: "cover",
          }}
          source={{
            uri: img.uri,
          }}
        />
      )}
      {img.image === undefined && (
        <Ionicons size={64} style={{ opacity: 0.2 }} name="image-outline" />
      )}
    </>
  );
}
