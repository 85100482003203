import React from "react";
import { View, PressableProps, Pressable } from "react-native";
import { default as Image } from "./Image";
import { imageRef } from "database/Image";
import { Ionicons } from "@expo/vector-icons";

export type InputImageProps = {
  image?: imageRef;
  width: number;
  onPress?: PressableProps["onPress"];
  onLongPress?: PressableProps["onLongPress"];
  disabled?: boolean;
  onLoad?: () => void;
  hasCaption?: boolean;
  placeholderColor?: string;
};

export default function InputImage(props: InputImageProps): JSX.Element {
  const { onPress, onLongPress } = props;
  return (
    <Pressable onPress={onPress} onLongPress={onLongPress}>
      <View
        style={{
          alignItems: "center",
          paddingTop: Math.floor(props.width * 0.05),
          paddingBottom: props.hasCaption ? 0 : Math.floor(props.width * 0.05),
        }}
      >
        {props.image !== undefined ? (
          <Image image={props.image} />
        ) : (
          <Ionicons
            size={64}
            style={{
              opacity: 0.2,
              color: props.placeholderColor ? props.placeholderColor : "#000",
            }}
            name="image-outline"
          />
        )}
      </View>
    </Pressable>
  );
}
