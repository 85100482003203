import Image, { imageRef } from "./Image";
import Firebase from "firebase";
import { db } from "./firebase";
import * as Profile from "database/Profile";
import "react-native-get-random-values";

export type JournalRef = Firebase.firestore.DocumentReference;
export interface JournalType extends Firebase.firestore.DocumentData {
  description: string;
  title: string;
  public: boolean;
  editor: [string];
  cover: imageRef;
  blocks: Array<string>;
}
export type BlockRef = Firebase.firestore.DocumentReference;
export interface BlockType extends Firebase.firestore.DocumentData {
  type: string;
  title?: string;
  image?: imageRef;
  description?: string;
  caption?: string;
}
export type blockCoupleType = {
  ref: BlockRef;
  data: BlockType;
};

export function getRef(id: string): Firebase.firestore.DocumentReference {
  return db.collection("journal").doc(id);
}

export async function remove(id: string): Promise<void> {
  const journalRef = getRef(id);
  const journal = (await (await journalRef.get()).data()) as JournalType;
  if (journal.editor.length > 1) {
    throw new Error("You should be the only editor");
  }
  // Delete Cover
  if (journal.cover !== undefined) {
    Image.removeUsedBy(journal.cover, journalRef).catch(() => {
      undefined;
    });
  }
  // Delete Blocks & Images (if any)
  const queryBlocks = await journalRef.collection("block").get();
  const deleteList: Array<Promise<void>> = [];
  queryBlocks.forEach((doc) => {
    const block = doc.data() as BlockType;
    deleteList.push(removeBlockAndContents(block, doc.ref));
  });
  await Promise.all(deleteList);
  await journalRef.delete();
}

export async function setCover(
  ref: JournalRef,
  imageRef: imageRef
): Promise<void> {
  const journal = <JournalType | undefined>(await ref.get()).data();
  if (journal !== undefined) {
    if (journal.cover !== undefined) {
      Image.removeUsedBy(journal.cover, ref);
    }
    Image.addUsedBy(imageRef, ref);
    ref.update({
      cover: imageRef,
      updatedTimestamp: Math.floor(Date.now() / 1000),
    });
  }
}

export async function setPublic(
  ref: JournalRef,
  _public: boolean
): Promise<void> {
  ref.update({
    public: _public,
    updatedTimestamp: Math.floor(Date.now() / 1000),
  });
}

export async function addEditor(
  ref: JournalRef,
  username: string
): Promise<void> {
  const uid = await Profile.getUserIDFromUsername(username);
  ref.update({
    editor: Firebase.firestore.FieldValue.arrayUnion(uid),
    updatedTimestamp: Math.floor(Date.now() / 1000),
  });
}

export async function removeEditor(
  ref: JournalRef,
  uid: string
): Promise<void> {
  ref.update({
    editor: Firebase.firestore.FieldValue.arrayRemove(uid),
    updatedTimestamp: Math.floor(Date.now() / 1000),
  });
}

/** BLOCKS */
export async function addBlock(
  ref: JournalRef,
  afterBlockId: string,
  type: string
): Promise<string> {
  const obj: BlockType = {
    type: "title",
  };
  if (type === "image") {
    obj.type = "image";
  } else if (type === "description") {
    obj.type = "description";
  }
  // Add Block to Subcollection
  const blockRef = await ref.collection("block").add(obj);
  // Keep Ordered List
  const journal = <JournalType | undefined>(await ref.get()).data();
  if (journal) {
    const blocks = journal.blocks || [];
    const index = blocks.findIndex((blockId) => afterBlockId === blockId) + 1;
    blocks.splice(index, 0, blockRef.id);
    await ref.update({
      blocks: blocks,
      updatedTimestamp: Math.floor(Date.now() / 1000),
    });
    return blockRef.id;
  }
  return "";
}

export async function updateBlock(
  ref: BlockRef,
  updatedValue: BlockType
): Promise<void> {
  const block = <BlockType | undefined>(await ref.get()).data();
  if (block) {
    if (block.type === "image" && updatedValue.image !== undefined) {
      if (block.image !== undefined) {
        Image.removeUsedBy(block.image, ref);
      }
      Image.addUsedBy(updatedValue.image, ref);
    }
    ref.set(updatedValue, { merge: true });
  }
}

export async function moveBlock(
  ref: JournalRef,
  blockId: string,
  shift: number
): Promise<void> {
  const journal = <JournalType | undefined>(await ref.get()).data();
  if (journal?.blocks) {
    const blocks = journal.blocks;
    const index = blocks.findIndex((_blockId) => blockId === _blockId);
    const exchangeId = blocks[index - shift];
    if (exchangeId !== undefined) {
      blocks[index - shift] = blockId;
      blocks[index] = exchangeId;
      ref.update({ blocks: blocks });
    }
  }
}

export async function removeBlock(
  ref: JournalRef,
  blockId: string
): Promise<void> {
  const journal = <JournalType | undefined>(await ref.get()).data();
  const blockRef = ref.collection("block").doc(blockId);
  const block = <BlockType | undefined>(await blockRef.get()).data();
  if (block !== undefined && journal?.blocks) {
    await removeBlockAndContents(block, blockRef);
    await ref.update({
      blocks: journal.blocks.filter((_blockId) => blockId !== _blockId),
    });
  }
}

async function removeBlockAndContents(
  block: BlockType,
  blockRef: BlockRef
): Promise<void> {
  if (block !== undefined) {
    if (block.type === "image" && block.image !== undefined) {
      await Image.removeUsedBy(block.image, blockRef);
    }
    await blockRef.delete();
  }
}
