import React from "react";

import {
  Text as DefaultText,
  TextProps as DefaultTextProps,
  View,
  StyleSheet,
} from "react-native";

export const _styles = StyleSheet.create({
  text: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    fontSize: 26.5,
    fontWeight: "600",
    paddingBottom: 9,
    paddingTop: 36,
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    letterSpacing: 0.25,
    textAlign: "left",
    paddingVertical: 5,
  },
  subtitle: {
    fontSize: 17,
    letterSpacing: 0.25,
    textAlign: "center",
  },
  caption: {
    fontSize: 14,
    textAlign: "left",
    paddingVertical: 5,
    paddingHorizontal: 3,
    fontStyle: "italic",
  },
});

export interface TextProps extends DefaultTextProps {
  children?: JSX.Element | string;
  styleId?: string;
}

export function TextStyled(props: TextProps): React.ReactElement {
  const { style, ...otherProps } = props;
  const ID = (props.styleId ? props.styleId : "text") as keyof typeof _styles;
  return (
    <View style={{ flexDirection: "row", paddingHorizontal: 12 }}>
      <DefaultText style={[_styles.text, _styles[ID], style]} {...otherProps}>
        {props.children}
      </DefaultText>
    </View>
  );
}

export default function Text(props: TextProps): React.ReactElement {
  const { style, ...otherProps } = props;
  return (
    <TextStyled style={[style, _styles.text]} {...otherProps}>
      {props.children}
    </TextStyled>
  );
}
