import Firebase from "firebase";
import "react-native-get-random-values";
import { db } from "./firebase";

export type ProfileRef = Firebase.firestore.DocumentReference;
export interface ProfileType extends Firebase.firestore.DocumentData {
  createdTimestamp: number;
  updatedTimestamp?: number;
  firstname?: string;
  username?: string;
}
export type UsernameRef = Firebase.firestore.DocumentReference;
export interface UsernameType extends Firebase.firestore.DocumentData {
  profile: ProfileRef;
}

export async function createProfile(uid: string): Promise<void> {
  await db
    .collection("user")
    .doc(uid)
    .set({ createdTimestamp: Math.floor(Date.now() / 1000) });
}

export async function getUsername(uid: string): Promise<string> {
  const _profile = (await db.collection("user").doc(uid).get()).data();
  if (_profile?.username) {
    return "@" + _profile.username;
  } else if (_profile?.firstname) {
    return "+" + _profile.firstname;
  } else {
    return "@?";
  }
}

export async function getUserIDFromUsername(username: string): Promise<string> {
  const users = await db
    .collection("user")
    .where("username", "==", username)
    .limit(1)
    .get();
  if (users.size === 0) {
    throw new Error(`${username} not found`);
  }
  return users.docs[0].id;
}

export async function setFirstname(
  ref: ProfileRef,
  firstname: string
): Promise<void> {
  return ref.update({
    firstname,
    updatedTimestamp: Math.floor(Date.now() / 1000),
  });
}

export async function setUsername(
  ref: ProfileRef,
  username: string
): Promise<void> {
  username = username.replace(/[^a-z0-9]/gi, "").toLocaleLowerCase();
  const _profile = (await ref.get())?.data() as ProfileType;
  const _currentUsername = _profile?.username;
  if (_currentUsername !== username) {
    const uid = await getUserIDFromUsername(username).catch(() => {
      ref.update({
        username: username,
        updatedTimestamp: Math.floor(Date.now() / 1000),
      });
    });
    if (uid !== undefined) {
      throw new Error("username already taken");
    }
  }
}
