import React, { useContext, useEffect, useState } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  Animated,
  TouchableWithoutFeedback,
  Alert,
  Platform,
} from "react-native";
import { TextStyled, Image } from "components/ui";
import { AuthContext } from "context/Auth";
import { db } from "database/firebase";
import * as Journal from "database/Journal";
import { JournalType } from "database/Journal";
import { StackScreenProps } from "@react-navigation/stack";
import { TabJournalParamList } from "navigation/Journal";
import Swipeable from "react-native-gesture-handler/Swipeable";
import { RectButton } from "react-native-gesture-handler";

type Props = StackScreenProps<TabJournalParamList, "JournalList">;
interface JournalList {
  [key: string]: JournalType;
}

export default function ({ navigation }: Props): React.ReactElement {
  const { user } = useContext(AuthContext);
  const [journaux, setJournaux] = useState<JournalList>({});

  useEffect(() => {
    const journalRef = db.collection("journal");
    const unsubscribe = journalRef
      .where("editor", "array-contains", user?.uid)
      .orderBy("updatedTimestamp", "desc")
      .onSnapshot((querySnapshot) => {
        if (querySnapshot !== undefined) {
          const newList: JournalList = {};
          querySnapshot.forEach((doc) => {
            newList[doc.id] = doc.data() as JournalType;
          });
          setJournaux(newList);
        }
      });
    return () => {
      unsubscribe();
    };
  }, [user?.uid]);

  const renderLeftActions = (
    key: string,
    _progress: Animated.AnimatedInterpolation,
    dragX: Animated.AnimatedInterpolation
  ) => {
    const trans = dragX.interpolate({
      inputRange: [0, 60, 100, 101],
      outputRange: [-20.5, 0, 0, 1],
    });
    return (
      <RectButton
        style={styles.leftAction}
        onPress={() => {
          Alert.alert(`Delete Journal`, undefined, [
            {
              text: "Cancel",
              style: "cancel",
            },
            {
              text: "Delete",
              onPress: () => {
                Journal.remove(key).catch((e) => {
                  Alert.alert("Error", e.message);
                });
              },
              style: "destructive",
            },
          ]);
        }}
      >
        <Animated.Text
          style={[
            styles.action,
            {
              transform: [{ translateX: trans }],
            },
          ]}
        >
          ✖︎ Delete
        </Animated.Text>
      </RectButton>
    );
  };

  return (
    <ScrollView style={{ backgroundColor: "#fff" }}>
      <View style={styles.container}>
        {Object.keys(journaux).length > 0 &&
          Object.keys(journaux).map(function (key: string) {
            const journal = journaux[key];
            return (
              <View key={key} style={{ marginBottom: 20 }}>
                <Swipeable
                  renderRightActions={
                    Platform.OS === "web"
                      ? undefined
                      : renderLeftActions.bind(undefined, key)
                  }
                >
                  <TouchableWithoutFeedback
                    style={{
                      backgroundColor: "#fff",
                      alignItems: "center",
                    }}
                    onPress={() => {
                      navigation.navigate("JournalEdit", {
                        id: key,
                        title: journal.title,
                      });
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#fff",
                        alignItems: "center",
                      }}
                    >
                      <TextStyled
                        styleId="title"
                        style={{ paddingTop: 9, paddingBottom: 4 }}
                      >
                        {journal.title}
                      </TextStyled>
                      <TextStyled
                        styleId="subtitle"
                        style={{ marginBottom: 9 }}
                      >
                        {journal.description}
                      </TextStyled>
                      <Image image={journal.cover} maxHeight={150} />
                    </View>
                  </TouchableWithoutFeedback>
                </Swipeable>
              </View>
            );
          })}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
    marginTop: 10,
  },
  leftAction: {
    backgroundColor: "#ff7869",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "row",
  },
  action: { color: "#fff", fontSize: 20 },
});
