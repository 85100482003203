import React, { useContext, useEffect, useState } from "react";
import { Alert, StyleSheet, Text, View } from "react-native";
import { Button, TextInput, Spacer } from "components/ui";
import { AuthContext } from "context/Auth";
import { db } from "database/firebase";
import { ProfileType, setFirstname, setUsername } from "database/Profile";

export default function (): Array<React.ReactElement> {
  const { user, signOut } = useContext(AuthContext);
  const [profile, setProfile] = useState<ProfileType>();
  const profileRef = user?.uid
    ? db.collection("user").doc(user.uid)
    : undefined;

  useEffect(() => {
    if (profileRef !== undefined) {
      const unsubscribe = profileRef.onSnapshot((doc) => {
        const _profile = doc.data() as ProfileType;
        setProfile(_profile);
      });
      return () => {
        unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  return [
    <View key="profile" style={styles.container}>
      <Text>Hello{profile?.username ? ` @${profile?.username}` : ""},</Text>
      <Text>You are logged with</Text>
      <Text>{user?.email}</Text>
      <Spacer size="lg" />
      <TextInput
        placeholder="Firstname"
        defaultValue={profile?.firstname}
        onBlur={(event) => {
          if (profileRef) {
            setFirstname(profileRef, event.nativeEvent.text.substring(0, 70));
          }
        }}
        maxLength={70}
      />
      <TextInput
        placeholder="Username"
        defaultValue={profile?.username}
        autoCapitalize="none"
        onBlur={(event) => {
          const username = event.nativeEvent.text.substring(0, 70);
          if (profileRef && profile?.username !== username) {
            setUsername(profileRef, username).catch((e) => {
              Alert.alert(`Error`, e.message);
            });
          }
        }}
        maxLength={70}
      />
    </View>,
    <View key="logout" style={styles.container}>
      <Button
        onPress={() => {
          signOut();
        }}
        title="Logout"
      />
    </View>,
  ];
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
  },
});
