import * as React from "react";
import Firebase from "firebase";

export type AuthContextProps = {
  user: Firebase.User | null;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => void;
  signUp: (email: string, password: string) => Promise<void>;
};

export const AuthContext = React.createContext<AuthContextProps>({
  user: null,
  signIn: async () => {
    return undefined;
  },
  signOut: () => {
    return undefined;
  },
  signUp: async () => {
    return undefined;
  },
});
