import Constants from "expo-constants";
import Firebase from "firebase";

const firebaseConfig = {
  apiKey: Constants.manifest?.extra?.FIREBASE_API_KEY,
  authDomain: Constants.manifest?.extra?.FIREBASE_AUTHDOMAIN,
  databaseURL: Constants.manifest?.extra?.FIREBASE_DATABASEURL,
  projectId: Constants.manifest?.extra?.FIREBASE_PROJECTID,
  storageBucket: Constants.manifest?.extra?.FIREBASE_STORAGEBUCKET,
  messagingSenderId: Constants.manifest?.extra?.FIREBASE_MESSAGINGSENDERID,
  appId: Constants.manifest?.extra?.FIREBASE_APPID,
};

export const app =
  Firebase.apps.length === 0
    ? Firebase.initializeApp(firebaseConfig)
    : Firebase.apps[0];

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const firebase = Firebase;
