import React, { useContext } from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { List, Edit } from "screens/journal";
import { NavButton } from "components/ui";
import { db } from "database/firebase";
import { AuthContext } from "context/Auth";
import { BottomBarContext } from "context/BottomBar";

export type TabJournalParamList = {
  JournalList: undefined;
  JournalEdit: {
    id: string;
    title: string;
  };
};

const TabJournalStack = createStackNavigator<TabJournalParamList>();

export default function TabJournalNavigator(): React.ReactElement {
  const { user } = useContext(AuthContext);
  const { setDisplayBottomBar } = useContext(BottomBarContext);
  return (
    <TabJournalStack.Navigator>
      <TabJournalStack.Screen
        name="JournalList"
        component={List}
        options={({ navigation }) => ({
          headerTitle: "Journal",
          headerRight: () => {
            return (
              <NavButton
                onPress={async () => {
                  const journalRef = db.collection("journal").doc();
                  await journalRef.set({
                    title: "New Journal",
                    editor: [user?.uid],
                    createdTimestamp: Math.floor(new Date().getTime() / 1000),
                    updatedTimestamp: Math.floor(new Date().getTime() / 1000),
                    public: false,
                  });
                  navigation.navigate("JournalEdit", {
                    id: journalRef.id,
                  });
                  return undefined;
                }}
              />
            );
          },
        })}
      />
      <TabJournalStack.Screen
        name="JournalEdit"
        component={Edit}
        listeners={{
          focus: () => {
            setDisplayBottomBar(false);
          },
          beforeRemove: () => {
            setDisplayBottomBar(true);
          },
        }}
        options={{
          headerShown: false,
        }}
      />
    </TabJournalStack.Navigator>
  );
}
