import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import {
  TextInput,
  Pressable,
  ScrollView,
  LayoutChangeEvent,
} from "react-native";
import { InputStyled } from "components/ui";
import Image, { imageRef } from "database/Image";
import InputImage from "components/ui/InputImage";
import * as Journal from "database/Journal";
import { BlockRef, BlockType, JournalRef } from "database/Journal";
import { AuthContext } from "context/Auth";

// eslint-disable-next-line
type PressableType = InstanceType<typeof Pressable>;

export interface EditableBlockProps {
  key: string;
  item: BlockType;
  itemRef: BlockRef;
  journal: JournalRef;
  focused: boolean;
  index: number;
  onLayout: (event: LayoutChangeEvent) => void;
  onPress: () => ScrollView | null;
  onSaved: () => void;
  width: number;
}

export interface EditableBlockRef {
  saveChanges(): void;
  scrollTo(scrollView: ScrollView): void;
}

export default React.forwardRef<EditableBlockRef, EditableBlockProps>(
  (props: EditableBlockProps, ref) => {
    const { user } = useContext(AuthContext);
    const inputRef = useRef<TextInput>(null);
    const touchableRef = useRef<PressableType>(null);

    const saveChanges = (): void => {
      if (inputRef.current && inputRef.current.isFocused()) {
        inputRef.current.blur();
      } else {
        props.onSaved();
      }
    };

    const scrollTo = (scrollView: ScrollView): void => {
      if (touchableRef.current) {
        touchableRef.current.measure(function (_x: number, y: number) {
          scrollView.scrollTo({
            x: 0,
            y: y - 125,
            animated: true,
          });
        });
      }
    };

    useEffect(() => {
      if (props.focused) {
        const scrollView = props.onPress();
        if (scrollView !== null) {
          scrollTo(scrollView);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.index]);

    useImperativeHandle(ref, () => ({ saveChanges, scrollTo }));

    const styles = {
      selected: {
        borderLeftColor: "#000",
        borderLeftWidth: 2,
        borderRightColor: "#000",
        borderRightWidth: 2,
        marginHorizontal: 2,
      },
      unselected: {
        marginHorizontal: 4,
      },
    };

    const onPress = () => {
      const scrollView = props.onPress();
      if (scrollView !== null) {
        scrollTo(scrollView);
      }
    };

    return (
      <Pressable
        ref={touchableRef}
        style={props.focused ? styles.selected : styles.unselected}
        onLayout={props.onLayout}
        onPress={onPress}
      >
        {props.item.type === "title" && (
          <InputStyled
            styleId="title"
            ref={inputRef}
            onBlur={(e) => {
              if (e.nativeEvent.text !== props.item.title) {
                Journal.updateBlock(props.itemRef, {
                  type: "title",
                  title: e.nativeEvent.text,
                }).then(() => {
                  props.onSaved();
                });
              } else {
                props.onSaved();
              }
            }}
            disabled={!props.focused}
            maxLength={70}
            defaultValue={props.item.title}
            placeholder="Title"
          />
        )}
        {props.item.type === "description" && (
          <InputStyled
            styleId="description"
            ref={inputRef}
            onBlur={(e) => {
              if (e.nativeEvent.text !== props.item.description) {
                Journal.updateBlock(props.itemRef, {
                  type: "description",
                  description: e.nativeEvent.text,
                }).then(() => {
                  props.onSaved();
                });
              } else {
                props.onSaved();
              }
            }}
            blurOnSubmit={false}
            disabled={!props.focused}
            multiline={true}
            defaultValue={props.item.description}
            placeholder="Description"
          />
        )}
        {props.item.type === "image" && (
          <>
            <InputImage
              image={props.item.image}
              width={props.width}
              disabled={!props.focused}
              hasCaption={
                props.item.caption !== undefined &&
                props.item.caption.length > 0
              }
              onPress={async () => {
                if (props.focused) {
                  await Image.pickImage(user, (imgRef: imageRef | null) => {
                    if (imgRef) {
                      Journal.updateBlock(props.itemRef, {
                        type: "image",
                        image: imgRef,
                      }).then(() => {
                        props.onSaved();
                      });
                    }
                  }).catch(() => {
                    props.onSaved();
                  });
                } else {
                  onPress();
                }
              }}
            />
            {((props.item.caption !== undefined &&
              props.item.caption.length > 0) ||
              props.focused) && (
              <InputStyled
                styleId="caption"
                ref={inputRef}
                onBlur={(e) => {
                  if (e.nativeEvent.text !== props.item.caption) {
                    Journal.updateBlock(props.itemRef, {
                      type: "image",
                      caption: e.nativeEvent.text,
                    });
                  }
                }}
                blurOnSubmit={true}
                disabled={!props.focused}
                multiline={false}
                defaultValue={props.item.caption}
                placeholder="Caption"
              />
            )}
          </>
        )}
      </Pressable>
    );
  }
);
