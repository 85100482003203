import { getUsername } from "database/Profile";
import React, { useEffect, useState } from "react";
import {
  Text as DefaultText,
  TextProps as DefaultTextProps,
} from "react-native";

export interface UsernameProps extends DefaultTextProps {
  uid: string;
}

export const Username = React.forwardRef<DefaultText, UsernameProps>(
  (props: UsernameProps, ref: React.ForwardedRef<DefaultText>) => {
    const [username, setUsername] = useState<string>("");
    const { uid, ...otherProps } = props;
    useEffect(() => {
      if (uid.includes(",")) {
        const uids = uid.split(",");
        Promise.all(uids.map(async (_uid) => getUsername(_uid))).then(
          (usernames) => {
            setUsername(usernames.join(", "));
          }
        );
      } else {
        getUsername(uid).then((_username) => {
          setUsername(_username);
        });
      }
    }, [uid]);
    return (
      <DefaultText ref={ref} {...otherProps}>
        {username.length ? username : uid}
      </DefaultText>
    );
  }
);
