import * as ImagePicker from "expo-image-picker";
import { db, storage } from "./firebase";
import Firebase from "firebase";
import { AuthContextProps } from "context/Auth";

export type imageRef = Firebase.firestore.DocumentReference;

export interface imageType extends Firebase.firestore.DocumentData {
  local?: string;
  width?: number;
  height?: number;
  picture?: string;
  picture960?: string;
}

async function getImage(ref: imageRef): Promise<imageType | undefined> {
  if (ref) {
    return (await ref.get()).data();
  }
  return Promise.resolve(undefined);
}

// Trigger the ImageLibrary
async function pickImage(
  user: AuthContextProps["user"],
  pickedCallback: (imgRef: imageRef | null) => void
): Promise<imageRef> {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: false,
    quality: 85,
  });

  if (!result.cancelled) {
    // Set Image Data
    const imgRef = db.collection("image").doc();
    const isLocalFile = result.uri.substring(0, 5) === "file:" ? true : false;
    await imgRef.set({
      local: isLocalFile ? result.uri : "not-a-file",
      width: result.width,
      height: result.height,
      owner: user?.uid,
    });
    // Do custom action
    if (pickedCallback !== null) {
      pickedCallback(imgRef);
    }
    // Upload Image
    const blob = await new Promise<Blob>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        reject(new TypeError("Network request failed"));
      };
      xhr.responseType = "blob";
      xhr.open("GET", result.uri, true);
      xhr.send(null);
    });

    const ref = storage.ref(imgRef.path);
    await ref.put(blob);
    // eslint-disable-next-line
    if (blob.close !== undefined) {
      // eslint-disable-next-line
      blob.close();
    }
    const url = await ref.getDownloadURL();
    imgRef.update({ picture: url });

    return imgRef;
  }
  return Promise.reject(new Error("user.cancelled"));
}

async function addUsedBy(
  imageRef: imageRef,
  ref: Firebase.firestore.DocumentReference
): Promise<imageRef> {
  await imageRef.update({
    usedBy: Firebase.firestore.FieldValue.arrayUnion(ref),
  });
  return imageRef;
}

async function removeUsedBy(
  imageRef: imageRef,
  ref: Firebase.firestore.DocumentReference
): Promise<imageRef> {
  await imageRef.update({
    usedBy: Firebase.firestore.FieldValue.arrayRemove(ref),
  });
  const image = (await imageRef.get()).data();
  if (image && image.usedBy.length === 0) {
    storage.ref(imageRef.path).delete();
    storage.ref(`image/960/${imageRef.id}`).delete();
    imageRef.delete();
  }
  return imageRef;
}

export default {
  getImage,
  pickImage,
  addUsedBy,
  removeUsedBy,
};
