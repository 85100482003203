import React, { useContext, useEffect, useRef, useState } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  View,
  Dimensions,
  Switch,
  Text,
  TouchableWithoutFeedback,
  Alert,
  Share,
} from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { ButtonRounded, InputStyled, Username, Button } from "components/ui";
import { db } from "database/firebase";
import Image, { imageRef } from "database/Image";
import * as Journal from "database/Journal";
import { blockCoupleType, BlockType, JournalType } from "database/Journal";
import { TabJournalParamList } from "navigation/Journal";
import InputImage from "components/ui/InputImage";
import { AuthContext } from "context/Auth";
import { default as EditableBlock, EditableBlockRef } from "./EditableBlock";
import AddButtons from "./AddButtons";
import { getUsername } from "database/Profile";

type Props = StackScreenProps<TabJournalParamList, "JournalEdit">;

export default function ({ route, navigation }: Props): React.ReactElement {
  const { user } = useContext(AuthContext);
  const [journal, setJournal] = useState<JournalType | undefined>(undefined);
  const [blocks, setBlocks] = useState<{ [key: string]: blockCoupleType }>({});
  const [editor, setEditor] = useState({ id: "" });
  const journalRef = db.collection("journal").doc(route.params.id);
  const scrollView = useRef<ScrollView>(null);
  const editableBlockPosition = useRef<{ [key: string]: number }>({});
  const editableBlock = useRef<EditableBlockRef | null>(null);
  const selectedBlock = useRef<string>("");

  const onShare = async () => {
    try {
      if (journal !== undefined) {
        if (Platform.OS === "ios") {
          await Share.share({
            message: `"${journal.title}" on WRITE.TF`,
            url: `https://write.tf/journal/${route.params.id}?token=${journal.token}`,
          });
        } else if (Platform.OS === "android") {
          await Share.share({
            title: `"${journal.title}" on WRITE.TF`,
            message: `https://write.tf/journal/${route.params.id}?token=${journal.token}`,
          });
        }
      }
    } catch (error) {
      undefined;
    }
  };

  useEffect(() => {
    const unsubscribe = journalRef.onSnapshot((doc) => {
      const _journal = doc.data() as JournalType;
      setJournal(_journal);
    });
    const unsubscribeBlocks = journalRef
      .collection("block")
      .onSnapshot((docs) => {
        const list: { [key: string]: blockCoupleType } = {};
        docs.forEach((block) => {
          list[block.id] = { ref: block.ref, data: block.data() as BlockType };
        });
        setBlocks(list);
      });
    return () => {
      unsubscribe();
      unsubscribeBlocks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params.id]);

  const blocksComponents: Array<JSX.Element> = [];
  if (
    blocks !== {} &&
    journal !== undefined &&
    journal.blocks !== undefined &&
    journal.blocks.length > 0
  ) {
    journal.blocks.forEach((key, index) => {
      if (blocks[key] !== undefined) {
        blocksComponents.push(
          <EditableBlock
            ref={(ref) => {
              if (editor.id !== "" && editor.id === key) {
                editableBlock.current = ref;
              }
            }}
            index={index}
            onLayout={(e) => {
              editableBlockPosition.current[key] = e.nativeEvent.layout.y;
            }}
            width={Dimensions.get("window").width}
            item={blocks[key].data}
            itemRef={blocks[key].ref}
            key={key}
            journal={journalRef}
            focused={editor.id !== "" && editor.id === key}
            onPress={() => {
              setEditor({ id: key });
              return scrollView.current;
            }}
            onSaved={() => {
              setEditor({ id: "" });
            }}
          />
        );
      }
    });
  }

  const getBlockOnScreen = function (event: {
    nativeEvent: {
      contentOffset: { y: number };
      layoutMeasurement: { height: number };
    };
  }) {
    const list = editableBlockPosition.current;
    const keys = Object.keys(list).sort((a, b) => (list[a] > list[b] ? 1 : -1));
    selectedBlock.current = keys.reduce((previous, current) => {
      return event.nativeEvent.contentOffset.y +
        event.nativeEvent.layoutMeasurement.height / 2 >
        list[current]
        ? current
        : previous;
    }, "");
  };

  const screenHeight = Dimensions.get("window").height;

  return (
    <KeyboardAvoidingView
      enabled
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1, backgroundColor: "#fff" }}
    >
      <View style={{ height: screenHeight }}>
        <ScrollView
          onMomentumScrollEnd={getBlockOnScreen}
          onScrollEndDrag={getBlockOnScreen}
          onScroll={Platform.OS === "web" ? getBlockOnScreen : undefined}
          scrollEventThrottle={5000}
          ref={scrollView}
        >
          {journal && (
            <View>
              <View
                style={{
                  backgroundColor: "#000",
                  justifyContent: "center",
                  minHeight: screenHeight,
                  flex: 1,
                  marginBottom: 60,
                }}
              >
                <InputStyled
                  styleId="title"
                  onBlur={(event) => {
                    journalRef.update({
                      title: event.nativeEvent.text.substring(0, 70),
                      updatedTimestamp: Math.floor(Date.now() / 1000),
                    });
                  }}
                  style={{ color: "#FFF" }}
                  maxLength={70}
                  defaultValue={journal.title}
                  placeholder="Title"
                />
                <InputStyled
                  styleId="subtitle"
                  defaultValue={journal.description}
                  style={{ color: "#FFF" }}
                  maxLength={140}
                  multiline={true}
                  onBlur={(event) => {
                    journalRef.update({
                      description: event.nativeEvent.text.substring(0, 140),
                      updatedTimestamp: Math.floor(Date.now() / 1000),
                    });
                  }}
                  placeholder="Description"
                  placeholderTextColor="#444"
                />
                <InputImage
                  image={journal.cover}
                  width={Dimensions.get("window").width}
                  placeholderColor="#fff"
                  onPress={async () => {
                    await Image.pickImage(user, (imgRef: imageRef | null) => {
                      if (imgRef) {
                        Journal.setCover(journalRef, imgRef);
                      }
                    }).catch(() => undefined);
                  }}
                />
              </View>
              {blocksComponents.length > 0 && blocksComponents}
              <View style={{ height: screenHeight / 2 }}></View>
              <View
                style={{
                  backgroundColor: "#f9f9f9",
                  paddingTop: 40,
                  paddingBottom: screenHeight / 4,
                  paddingHorizontal: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    paddingBottom: 40,
                  }}
                >
                  Parameters
                </Text>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    paddingBottom: 26,
                  }}
                >
                  <Text style={{ flexGrow: 1, fontSize: 20, lineHeight: 31 }}>
                    Journal is {journal.public ? "public" : "private"}
                  </Text>
                  <Switch
                    trackColor={{ false: "#000", true: "#17e895" }}
                    thumbColor={journal.public ? "#fff" : "#fff"}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={(value) => {
                      Journal.setPublic(journalRef, value);
                    }}
                    value={journal.public}
                  />
                </View>
                {journal !== undefined && journal.token !== undefined && (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      paddingBottom: 26,
                    }}
                  >
                    <Text style={{ flexGrow: 1, fontSize: 20, lineHeight: 31 }}>
                      Share Journal
                    </Text>
                    <Button size={40} title={"share"} onPress={onShare} />
                  </View>
                )}
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    paddingBottom: 10,
                  }}
                >
                  <Text style={{ flexGrow: 1, fontSize: 20, lineHeight: 31 }}>
                    Editors
                  </Text>
                  <View style={{ paddingTop: 7 }}>
                    {journal.editor &&
                      journal.editor.map((user) => {
                        return (
                          <TouchableWithoutFeedback
                            key={user}
                            onPress={() => {
                              if (journal.editor.length > 1) {
                                getUsername(user).then((username) => {
                                  Alert.alert(`Remove ${username}`, undefined, [
                                    {
                                      text: "Cancel",
                                      style: "cancel",
                                    },
                                    {
                                      text: "Remove",
                                      onPress: () => {
                                        Journal.removeEditor(journalRef, user);
                                      },
                                      style: "destructive",
                                    },
                                  ]);
                                });
                              }
                            }}
                          >
                            <Username
                              style={{
                                fontSize: 16,
                                paddingBottom: 10,
                              }}
                              uid={user}
                            />
                          </TouchableWithoutFeedback>
                        );
                      })}
                    <ButtonRounded
                      size={20}
                      title={"✚"}
                      onPress={() => {
                        Alert.prompt(
                          "Enter editor",
                          "Type @username to add to journal",
                          (text: string) => {
                            Journal.addEditor(journalRef, text).catch((e) => {
                              Alert.alert(`Error`, e.message);
                            });
                          }
                        );
                      }}
                    />
                  </View>
                </View>
              </View>
            </View>
          )}
        </ScrollView>
      </View>
      <AddButtons
        onPressTitle={async () => {
          setEditor({
            id: await Journal.addBlock(
              journalRef,
              selectedBlock.current || "",
              "title"
            ),
          });
        }}
        onPressDescription={async () => {
          setEditor({
            id: await Journal.addBlock(
              journalRef,
              selectedBlock.current || "",
              "description"
            ),
          });
        }}
        onPressImage={async () => {
          setEditor({
            id: await Journal.addBlock(
              journalRef,
              selectedBlock.current || "",
              "image"
            ),
          });
        }}
      />
      <ButtonRounded
        style={{ position: "absolute", top: 27, left: 10 }}
        size={40}
        onPress={() => {
          navigation.goBack();
        }}
        title="←"
      />
      {editor.id !== "" && [
        <ButtonRounded
          key="save"
          style={{ position: "absolute", top: 27, right: 10 }}
          size={40}
          onPress={() => {
            editableBlock.current?.saveChanges();
          }}
          title="✓"
        />,

        <ButtonRounded
          key="move-up"
          style={{ position: "absolute", top: 27, right: 72 }}
          size={40}
          title="↑"
          onPress={() => {
            Journal.moveBlock(journalRef, editor.id || "", 1);
          }}
        />,

        <ButtonRounded
          key="move-down"
          style={{ position: "absolute", top: 27, right: 118 }}
          size={40}
          title="↓"
          onPress={() => {
            Journal.moveBlock(journalRef, editor.id || "", -1);
          }}
        />,

        <ButtonRounded
          key="delete"
          style={{ position: "absolute", top: 27, right: 180 }}
          size={40}
          title="✖︎"
          onPress={() => {
            Journal.removeBlock(journalRef, editor.id || "");
            setEditor({ id: "" });
          }}
        />,
      ]}
    </KeyboardAvoidingView>
  );
}
