import React, { useEffect } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { Root } from "navigation/Root";
import { StatusBar } from "expo-status-bar";
import { AuthContext, AuthContextProps } from "context/Auth";
import Storage from "services/storage";
import { auth } from "database/firebase";
import { createProfile } from "database/Profile";

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default function SecuredApp(): React.ReactElement {
  const [refreshed, setRefreshed] = React.useState(0);

  const authContext: AuthContextProps = React.useMemo(
    () => ({
      user: auth.currentUser,
      signIn: async (email, password) => {
        auth
          .signInWithEmailAndPassword(email, password)
          .then((result) => {
            Storage.setItem(
              "userCredentials",
              JSON.stringify({ email, password, uid: result.user?.uid })
            );
          })
          .catch(function (error) {
            const errorMessage = error.message;
            alert(errorMessage);
            Storage.deleteItem("userCredentials");
          });
      },
      signOut: () => {
        auth.signOut();
        Storage.deleteItem("userCredentials");
      },
      signUp: async (email, password) => {
        auth
          .createUserWithEmailAndPassword(email, password)
          .then((result) => {
            Storage.setItem(
              "userCredentials",
              JSON.stringify({ email, password, uid: result.user?.uid })
            );
            if (result.user?.uid) {
              createProfile(result.user.uid);
            }
          })
          .catch(function (error) {
            const errorMessage = error.message;
            alert(errorMessage);
            Storage.deleteItem("userCredentials");
          });
      },
    }),
    []
  );

  useEffect(() => {
    const bootstrapAsync = async () => {
      await timeout(1000);
      if (authContext && auth.currentUser === null) {
        try {
          const uC = await Storage.getItem("userCredentials");
          if (uC) {
            try {
              const { email, password } = JSON.parse(uC);
              authContext.signIn(email, password);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    };
    bootstrapAsync();

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      authContext.user = user;
      if (user === null) {
        try {
          const uC = await Storage.getItem("userCredentials");
          if (uC) {
            try {
              const { email, password } = JSON.parse(uC);
              await authContext.signIn(email, password);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
      setRefreshed(Date.now());
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={authContext}>
      {refreshed > 0 ? (
        <NavigationContainer>
          <Root authenticated={authContext.user !== null} />
          <StatusBar style="auto" />
        </NavigationContainer>
      ) : undefined}
    </AuthContext.Provider>
  );
}
