import React from "react";

import {
  Text,
  View,
  StyleSheet,
  Pressable,
  PressableProps as DefaultPressableProps,
  ViewStyle,
  TextStyle,
} from "react-native";

interface Styles {
  view: ViewStyle;
  text: TextStyle;
  viewRounded: ViewStyle;
  textRounded: TextStyle;
}

const styles = StyleSheet.create<Styles>({
  view: {
    backgroundColor: "#000",
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 12,
    paddingHorizontal: 18,
    borderRadius: 4,
    elevation: 3,
  },
  text: {
    fontSize: 16,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "#fff",
  },
  viewRounded: {
    backgroundColor: "#000",
    shadowRadius: 5,
    shadowColor: "#000",
    shadowOpacity: 0.25,
    shadowOffset: { width: 0, height: 0 },
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: 0,
    paddingHorizontal: 0,
    borderRadius: 1000,
  },
  textRounded: {
    fontSize: 28,
    lineHeight: 21,
    fontWeight: "bold",
    letterSpacing: 0.25,
    color: "#fff",
    width: "100%",
    textAlign: "center",
    borderRadius: 1000,
  },
});

export interface ButtonProps extends DefaultPressableProps {
  title?: string;
  size?: number;
  styleView?: ViewStyle;
  styleText?: TextStyle;
}

export default function (props: ButtonProps): React.ReactElement {
  const { title = "Button", ...otherProps } = props;
  const viewStyle: ViewStyle = {
    opacity: props.disabled ? 0.25 : 1,
  };
  return (
    <Pressable {...otherProps}>
      <View style={[styles.view, viewStyle]}>
        <Text style={[styles.text]}>{title}</Text>
      </View>
    </Pressable>
  );
}

export function ButtonRounded(props: ButtonProps): React.ReactElement {
  const { title = "Button", size = 40, ...otherProps } = props;
  const viewStyle: ViewStyle = {
    opacity: props.disabled ? 0.25 : 1,
    width: size,
    height: size,
  };
  const textStyle: TextStyle = {
    lineHeight: size / 2,
    fontSize: size / 2,
    paddingLeft: 0.5,
    paddingTop: 2,
  };
  return (
    <Pressable {...otherProps}>
      <View style={[styles.viewRounded, viewStyle, props.styleView]}>
        <Text style={[styles.textRounded, textStyle, props.styleText]}>
          {title}
        </Text>
      </View>
    </Pressable>
  );
}
