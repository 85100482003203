import React from "react";

import { View, ViewProps as DefaultViewProps, ViewStyle } from "react-native";

export interface ViewProps extends DefaultViewProps {
  size?: string;
}

export default function (props: ViewProps): React.ReactElement {
  const viewStyle: ViewStyle = {
    height: 12,
  };
  if (props.size === "lg") {
    viewStyle.height = 18;
  } else if (props.size === "sm") {
    viewStyle.height = 6;
  }
  return <View style={viewStyle} />;
}
