import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { MyProfile } from "screens/profile";

export type TabProfileParamList = {
  MyProfile: undefined;
};
const TabProfileStack = createStackNavigator<TabProfileParamList>();

export default function TabProfileNavigator(): React.ReactElement {
  return (
    <TabProfileStack.Navigator>
      <TabProfileStack.Screen
        name="MyProfile"
        component={MyProfile}
        options={{ headerTitle: "Profile" }}
      />
    </TabProfileStack.Navigator>
  );
}
