import React from "react";
import {
  createStackNavigator,
  StackScreenProps,
} from "@react-navigation/stack";
import { SignIn, SignUp } from "screens/guest";
import BottomTabNavigator from "./BottomTabNavigator";

const Stack = createStackNavigator();

export type RootStackParamList = {
  App: undefined;
  SignIn: undefined;
  SignUp: undefined;
};

type RootProps = {
  authenticated: boolean;
};

export type SignInProps = StackScreenProps<RootStackParamList, "SignIn">;
export type SignUpProps = StackScreenProps<RootStackParamList, "SignUp">;

export function Root(props: RootProps): React.ReactElement {
  return (
    <Stack.Navigator
      headerMode="none"
      screenOptions={{ animationEnabled: false }}
    >
      {props.authenticated ? (
        <Stack.Screen name="App" component={BottomTabNavigator} />
      ) : (
        <>
          <Stack.Screen name="SignIn" component={SignIn} />
          <Stack.Screen name="SignUp" component={SignUp} />
        </>
      )}
    </Stack.Navigator>
  );
}
