import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  main: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  header: {
    height: "10%",
    justifyContent: "flex-end",
  },
  footer: {
    alignItems: "center",
    justifyContent: "flex-start",
    height: "10%",
  },
  body: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "80 %",
  },
});
