import React, { useRef, useState } from "react";
import { View, Animated, Easing, GestureResponderEvent } from "react-native";
import { ButtonRounded } from "components/ui";

type AddButtonsProps = {
  onPressTitle: (event: GestureResponderEvent) => void;
  onPressDescription: (event: GestureResponderEvent) => void;
  onPressImage: (event: GestureResponderEvent) => void;
};

export default function (props: AddButtonsProps): JSX.Element {
  const positionAnim = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;
  const scaleAnim = useRef(new Animated.Value(0)).current;
  const [opened, setOpened] = useState(false);
  const distance = 75;

  const toggleButtons = () => {
    const [toValuePosition, toValueScale] = opened
      ? [{ x: 0, y: 0 }, 0]
      : [{ x: -distance, y: -distance * 0.70710678118 }, 1.2];
    setOpened(!opened);
    positionAnim.stopAnimation();
    scaleAnim.stopAnimation();
    Animated.parallel([
      Animated.timing(positionAnim, {
        toValue: toValuePosition,
        duration: 150,
        easing: Easing.ease,
        useNativeDriver: true,
      }),
      Animated.timing(scaleAnim, {
        toValue: toValueScale,
        duration: 150,
        easing: Easing.ease,
        useNativeDriver: true,
      }),
    ]).start();
  };

  return (
    <View
      style={{
        position: "absolute",
        right: 15,
        bottom: 20,
      }}
    >
      <Animated.View
        style={{
          position: "absolute",
          transform: [{ scale: scaleAnim }, { translateY: positionAnim.x }],
        }}
      >
        <ButtonRounded
          title="🖼"
          onPress={props.onPressImage}
          onPressOut={() => {
            toggleButtons();
          }}
        />
      </Animated.View>
      <Animated.View
        style={{
          position: "absolute",
          transform: [
            { scale: scaleAnim },
            { translateX: positionAnim.y },
            { translateY: positionAnim.y },
          ],
        }}
      >
        <ButtonRounded
          title="T"
          onPress={props.onPressTitle}
          onPressOut={() => {
            toggleButtons();
          }}
        />
      </Animated.View>
      <Animated.View
        style={{
          position: "absolute",
          transform: [{ scale: scaleAnim }, { translateX: positionAnim.x }],
        }}
      >
        <ButtonRounded
          title="t"
          onPress={props.onPressDescription}
          onPressOut={() => {
            toggleButtons();
          }}
        />
      </Animated.View>
      <ButtonRounded
        title={opened ? "✖︎" : "✚"}
        onPress={() => {
          toggleButtons();
        }}
      />
    </View>
  );
}
