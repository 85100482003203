import React, { useEffect, useState } from "react";
import { ScrollView, View, Dimensions } from "react-native";
import { StackScreenProps } from "@react-navigation/stack";
import { ButtonRounded, Image } from "components/ui";
import { db } from "database/firebase";
import { blockCoupleType, BlockType, JournalType } from "database/Journal";
import { TabHomeParamList } from "navigation/Home";
import { TextStyled } from "components/ui/Text";

type Props = StackScreenProps<TabHomeParamList, "JournalView">;

export default function ({ route, navigation }: Props): React.ReactElement {
  const [journal, setJournal] = useState<JournalType | undefined>(undefined);
  const [blocks, setBlocks] = useState<{ [key: string]: blockCoupleType }>({});
  const journalRef = db.collection("journal").doc(route.params.id);

  useEffect(() => {
    const unsubscribe = journalRef.onSnapshot((doc) => {
      const _journal = doc.data() as JournalType;
      setJournal(_journal);
    });
    const unsubscribeBlocks = journalRef
      .collection("block")
      .onSnapshot((docs) => {
        const list: { [key: string]: blockCoupleType } = {};
        docs.forEach((block) => {
          list[block.id] = { ref: block.ref, data: block.data() as BlockType };
        });
        setBlocks(list);
      });
    return () => {
      unsubscribe();
      unsubscribeBlocks();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route.params.id]);

  const blocksComponents: Array<JSX.Element> = [];
  if (
    blocks !== {} &&
    journal !== undefined &&
    journal.blocks !== undefined &&
    journal.blocks.length > 0
  ) {
    journal.blocks.forEach((key) => {
      if (blocks[key] !== undefined) {
        if (blocks[key].data.type === "title") {
          blocksComponents.push(
            <TextStyled styleId="title" key={key}>
              {blocks[key].data.title}
            </TextStyled>
          );
        } else if (blocks[key].data.type === "description") {
          blocksComponents.push(
            <TextStyled styleId="description" key={key}>
              {blocks[key].data.description}
            </TextStyled>
          );
        } else if (blocks[key].data.type === "image") {
          blocksComponents.push(
            <View
              key={key}
              style={{ marginVertical: 12, alignItems: "center" }}
            >
              <Image image={blocks[key].data.image} />
              {blocks[key].data.caption !== undefined &&
                blocks[key].data.caption.length > 0 && (
                  <TextStyled styleId="caption">
                    {blocks[key].data.caption}
                  </TextStyled>
                )}
            </View>
          );
        }
      }
    });
  }

  const screenHeight = Dimensions.get("window").height;

  return (
    <View style={{ height: screenHeight }}>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        style={{ flex: 1, backgroundColor: "#fff" }}
      >
        {journal && (
          <View>
            <View
              style={{
                backgroundColor: "#202020",
                justifyContent: "center",
                alignItems: "center",
                minHeight: screenHeight,
                flex: 1,
                marginBottom: 60,
              }}
            >
              <TextStyled styleId="title" style={{ color: "#fff" }}>
                {journal.title}
              </TextStyled>
              <TextStyled
                styleId="subtitle"
                style={{ color: "#fff", marginBottom: 9 }}
              >
                {journal.description}
              </TextStyled>
              <Image image={journal.cover} />
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              {blocksComponents}
            </View>
            <View
              style={{
                minHeight: screenHeight / 2,
              }}
            ></View>
          </View>
        )}
      </ScrollView>

      <ButtonRounded
        style={{ position: "absolute", top: 27, left: 10 }}
        size={35}
        onPress={() => {
          navigation.goBack();
        }}
        title="←"
      />
    </View>
  );
}
