import React, { useContext, useEffect, useState } from "react";
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import { Spacer, TextStyled, Image, Username } from "components/ui";
import { AuthContext } from "context/Auth";
import { db } from "database/firebase";
import { JournalType } from "database/Journal";
import { StackScreenProps } from "@react-navigation/stack";
import { TabHomeParamList } from "navigation/Home";

type Props = StackScreenProps<TabHomeParamList, "Home">;
interface JournalList {
  [key: string]: JournalType;
}

export default function ({ navigation }: Props): React.ReactElement {
  const { user } = useContext(AuthContext);
  const [journaux, setJournaux] = useState<JournalList | undefined>(undefined);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    const unsubscribe = db
      .collection("journal")
      .where("public", "==", true)
      .orderBy("updatedTimestamp", "desc")
      .onSnapshot((querySnapshot) => {
        if (querySnapshot !== undefined) {
          const newList: JournalList = {};
          querySnapshot.forEach((doc) => {
            newList[doc.id] = doc.data() as JournalType;
          });
          setJournaux(newList);
        }
      });
    return () => {
      unsubscribe();
    };
  }, [user?.uid, reload]);

  return (
    <ScrollView
      style={{ backgroundColor: "#fff" }}
      refreshControl={
        <RefreshControl
          refreshing={journaux === {}}
          onRefresh={() => {
            setJournaux({});
            setReload(!reload);
          }}
        />
      }
    >
      <View style={styles.container}>
        <Spacer size="lg" />
        {journaux !== undefined &&
          Object.keys(journaux).length > 0 &&
          Object.keys(journaux).map(function (key: string) {
            const journal = journaux[key];
            return (
              <TouchableOpacity
                key={key}
                style={{ alignItems: "center", marginBottom: 50 }}
                onPress={() => {
                  navigation.navigate("JournalView", {
                    id: key,
                    username: "",
                  });
                }}
              >
                <TextStyled styleId="title">{journal.title}</TextStyled>
                <TextStyled styleId="subtitle" style={{ marginBottom: 9 }}>
                  {journal.description}
                </TextStyled>
                <Image image={journal.cover} maxHeight={150} />
                {journal.editor && (
                  <Username
                    style={{ marginTop: 2, fontStyle: "italic" }}
                    uid={journal.editor.join(",")}
                  />
                )}
              </TouchableOpacity>
            );
          })}
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
