import React from "react";
import { _styles as textStyles } from "./Text";

import {
  TextInput as DefaultTextInput,
  TextInputProps as DefaultTextInputProps,
  StyleSheet,
  View,
  Text,
} from "react-native";

const _styles = StyleSheet.create({
  input: {
    height: 40,
    marginBottom: 5,
    borderRadius: 4,
    width: "100%",
    textAlign: "center",
    backgroundColor: "#EFEFEF",
  },
});

export interface TextInputProps extends DefaultTextInputProps {
  styleId?: string;
  disabled?: boolean;
}

export default function TextInput(props: TextInputProps): React.ReactElement {
  const { style, ...otherProps } = props;
  return <DefaultTextInput style={[style, _styles.input]} {...otherProps} />;
}

export function InputEmail(props: TextInputProps): React.ReactElement {
  return (
    <TextInput
      {...props}
      textContentType="emailAddress"
      autoCapitalize="none"
      placeholder={props.placeholder ? props.placeholder : "email address"}
    />
  );
}

export function InputPassword(props: TextInputProps): React.ReactElement {
  return (
    <TextInput
      {...props}
      textContentType="password"
      secureTextEntry={true}
      placeholder={props.placeholder ? props.placeholder : "password"}
    />
  );
}

export const InputStyled = React.forwardRef<DefaultTextInput, TextInputProps>(
  (props: TextInputProps, ref: React.ForwardedRef<DefaultTextInput>) => {
    const { style, ...otherProps } = props;
    const ID = (
      props.styleId ? props.styleId : "text"
    ) as keyof typeof textStyles;
    return (
      <View
        style={{
          paddingHorizontal: 12,
          width: "100%",
        }}
      >
        {props.disabled === true ? (
          <Text
            style={[textStyles.text, textStyles[ID], style]}
            {...otherProps}
          >
            {props.defaultValue || props.styleId}
          </Text>
        ) : (
          <DefaultTextInput
            ref={ref}
            style={[textStyles.text, textStyles[ID], style]}
            {...otherProps}
          />
        )}
      </View>
    );
  }
);
