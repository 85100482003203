import React from "react";

import {
  Text,
  View,
  StyleSheet,
  Pressable,
  PressableProps as DefaultPressableProps,
  ViewStyle,
  TextStyle,
} from "react-native";

interface Styles {
  view: ViewStyle;
  text: TextStyle;
}

const styles = StyleSheet.create<Styles>({
  view: {
    backgroundColor: "#000",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
    elevation: 3,
    width: 30,
    marginRight: 10,
  },
  text: {
    fontSize: 18,
    textAlign: "center",
    lineHeight: 30,
    height: 30,
    fontWeight: "bold",
    color: "#fff",
  },
});

export interface NavButtonProps extends DefaultPressableProps {
  title?: string;
}

export default function (props: NavButtonProps): React.ReactElement {
  const { onPress, title = "✚" } = props;
  const viewStyle: ViewStyle = {
    opacity: props.disabled ? 0.25 : 1,
  };
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.view, viewStyle]}>
        <Text style={[styles.text]}>{title}</Text>
      </View>
    </Pressable>
  );
}
